<template>
  <div>
    <c-search-box @enter="getMenuList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="DEVICE_CD"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="deviceTypeCd"
            label="디바이스구분"
            v-model="searchParam.deviceTypeCd"
            @input="getMenuList"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
            @input="getMenuList"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-tree-table
          title="메뉴목록"
          parentProperty="upMenuId"
          customID="sysMenuId"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :hideBottom="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getMenuList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="메뉴정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && isManager" label="등록" icon="add" @btnClicked="addMenu" />
                <c-btn
                  v-if="editable && deleteable && isManager"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="menu"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="menu"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveMenu"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  label="메뉴명"
                  name="menuNm"
                  v-model="menu.menuNm">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :editable="false"
                  codeGroupCd="DEVICE_CD"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  name="deviceTypeCd"
                  label="디바이스구분"
                  v-model="menu.deviceTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="메뉴ID"
                  name="sysMenuId"
                  v-model="menu.sysMenuId">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'selectUpMenu', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeUpMenu', color: 'red' }
                  ] : null"
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="상위 메뉴"
                  name="upMenuNm"
                  v-model="menu.upMenuNm"
                  @selectUpMenu="() => { isMenuOpen = true }"
                  @removeUpMenu="removeUpMenu">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="메뉴레벨"
                  name="menuLvl"
                  v-model="menu.menuLvl">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable && isManager"
                  label="메뉴 URL"
                  name="url"
                  v-model="menu.url">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="메뉴 아이콘"
                  name="menuIcon"
                  v-model="menu.menuIcon">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  type="number"
                  label="정렬 순서"
                  name="sortOrder"
                  v-model="menu.sortOrder">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="메뉴명(영어)"
                  name="menuNmEn"
                  v-model="menu.menuNmEn">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="메뉴명(일본어)"
                  name="menuNmJa"
                  v-model="menu.menuNmJa">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="메뉴명(중국어-간체)"
                  name="menuNmZhCn"
                  v-model="menu.menuNmZhCn">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="메뉴명(중국어-번체)"
                  name="menuNmZhTw"
                  v-model="menu.menuNmZhTw">
                </c-text>
              </div>
              <div class="col-6" v-if="searchParam.deviceTypeCd == 'P'">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="대시보드 여부"
                  name="dashboardFlag"
                  v-model="menu.dashboardFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="menu.useFlag"
                />
              </div>
              <div class="col-3" v-if="searchParam.deviceTypeCd == 'P'">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="매뉴얼 사용여부"
                  name="manualFlag"
                  v-model="menu.manualFlag"
                />
              </div>
              <div class="col-3" v-if="searchParam.deviceTypeCd == 'P'">
                <c-btn v-if="editable && menu.manualFlag == 'Y'" :editable="editable && dataeditable" label="매뉴얼 관리" color="red" icon="quiz" @btnClicked="addManual(menu)" />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="시스템전용메뉴"
                  name="systemFlag"
                  v-model="menu.systemFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>

    <q-dialog
      ref="menuDialog"
      persistent
      v-model="isMenuOpen">
      <q-card class="menu-card">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
          <div class="text-h6">메뉴</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectMenu"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                {{menuNm}}
              </q-chip>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="menu-content p-2">
          <q-tree
            ref="menuTree"
            :nodes="menuTree"
            node-key="sysMenuId"
            label-key="menuNm"
            children-key="children"
            no-nodes-label="메뉴가 없습니다."
            no-results-label=""
            :default-expand-all="false"
            selected-color="primary"
            :selected.sync="selectedMenu"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'user-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'menuNm',
            field: 'menuNm',
            label: '메뉴명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      menu: {
        sysMenuId: '',  // 메뉴 SEQ
        upMenuId: '',  // 상위메뉴 ID
        menuNm: '',  // 메뉴명
        upMenuNm: '',
        menuNmEn: '',  // 메뉴명-영문
        menuNmJa: '',  // 메뉴명-일본어
        menuNmZhCn: '',  // 메뉴명-중국어간체
        menuNmZhTw: '',  // 메뉴명-중국어번체
        menuLvl: -1,  // 메뉴 레벨
        url: '',  // URL
        menuColor: '',  // color
        menuIcon: '',  // icon
        sortOrder: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        manualFlag: 'N',
        dashboardFlag: 'N',
        deviceTypeCd: 'P',
        systemFlag: 'N',
      },
      originMenu: {
        sysMenuId: '',  // 메뉴 SEQ
        upMenuId: '',  // 상위메뉴 ID
      },
      searchParam: {
        useFlag: 'Y',
        deviceTypeCd: 'P',
      },
      useFlagItems: [],
      isMenuOpen: false,
      menuNm: '메뉴를 선택하세요.',
      menuTree: [],
      selectedMenu: null,
      selectedSysMenu: {},
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      lvl1Check: false,
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.menu.insert.url,
      saveType: 'POST',
      searchUrl: '',
      isManager: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    ifUpdate() {
      return this.editable && this.menu.sysMenuId
    }
  },
  watch: {
    selectedMenu() {
      let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);

      // this.menuCd = this.selectedMenu;
      this.menuNm = data.menuNm;
    },
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.isManager = (this.$store.getters.user.userId === 'U000000001');
      this.dataeditable = false;
      this.searchUrl = selectConfig.sys.menu.list.url
      this.detailUrl = selectConfig.sys.menu.get.url
      this.insertUrl = transactionConfig.sys.menu.insert.url
      this.updateUrl = transactionConfig.sys.menu.update.url
      this.deleteUrl = transactionConfig.sys.menu.delete.url
      this.getList();
      this.reset();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getMenuList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.searchUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.menuTree = this.convertTree(_result.data);
      },);
      this.saveable = false;
      this.deleteable = false;
      this.selectedSysMenu = {};
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upMenuId',
        customID: 'sysMenuId',
      });
      return tree;
    },
    selectMenu() {
      if (this.selectedMenu) {
        let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);
        this.menu.upMenuId = data.sysMenuId;
        this.menu.upMenuNm= data.menuNm;
        this.menu.menuLvl = Number(data.menuLvl) + 1;
        this.$refs['menuDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '메뉴를 선택하세요.', // 메뉴를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeUpMenu() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '상위메뉴가 없는 경우 최상위 메뉴로 들어가게 됩니다.\n\r진행하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.menu.upMenuId = null;
          this.menu.upMenuNm = '';
          this.menu.menuLvl = 0;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addMenu() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.menu = {
        sysMenuId: '',  // 메뉴 SEQ
        upMenuId: this.selectedSysMenu.sysMenuId === undefined ? null : this.selectedSysMenu.sysMenuId,
        upMenuNm: this.selectedSysMenu.sysMenuNm === undefined ? null : this.selectedSysMenu.sysMenuNm,
        menuLvl: this.selectedSysMenu.sysMenuId === undefined ? 0 : this.selectedSysMenu.menuLvl + 1,
        menuNm: '',  // 메뉴명
        menuNmEn: '',  // 메뉴명-영문
        menuNmJa: '',  // 메뉴명-일본어
        menuNmZhCn: '',  // 메뉴명-중국어간체
        menuNmZhTw: '',  // 메뉴명-중국어번체
        url: '',  // URL
        menuColor: '',  // color
        menuIcon: '',  // icon
        sortOrder: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        manualFlag: 'N',
        dashboardFlag: 'N',
        systemFlag: 'N',
        deviceTypeCd: this.searchParam.deviceTypeCd,
      };
    },
    rowClick(row) {
      this.selectedSysMenu = row;
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.dataeditable = true;
      this.$http.url = this.$format(this.detailUrl, row.sysMenuId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.menu = _result.data;
        this.originMenu.sysMenuId = this.$_.clone(_result.data.sysMenuId)
        this.originMenu.upMenuId = this.$_.clone(_result.data.upMenuId)
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedSysMenu = {};
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.menu = {
        sysMenuId: '',  // 메뉴 SEQ
        upMenuId: '',  // 상위메뉴 ID
        upMenuNm: '',
        menuNm: '',  // 메뉴명
        menuNmEn: '',  // 메뉴명-영문
        menuNmJa: '',  // 메뉴명-일본어
        menuNmZhCn: '',  // 메뉴명-중국어간체
        menuNmZhTw: '',  // 메뉴명-중국어번체
        menuLvl: 0,  // 메뉴 레벨
        url: '',  // URL
        menuColor: '',  // color
        menuIcon: '',  // icon
        sortOrder: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        manualFlag: 'N',
        dashboardFlag: 'N',
        systemFlag: 'N',
        deviceTypeCd: this.searchParam.deviceTypeCd,
      };
    },
    saveMenu() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = '저장하시겠습니까?';
          if (this.originMenu.sysMenuId === this.menu.sysMenuId && this.originMenu.upMenuId !== this.menu.upMenuId) {
            message = '상위메뉴가 변경됨으로 인해 해당 메뉴의 하위 정보들 또한 같이 이동됩니다.\n\r저장하시겠습니까?';
          }
          if (this.saveType == 'PUT' && this.menu.sysMenuId == this.menu.upMenuId) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '메뉴가 상위메뉴와 동일할 수 없습니다.\n\r상위메뉴를 변경하세요', // 메뉴를 선택하세요.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: message,
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.menu.sysMenuId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ sysMenuId: result.data.sysMenuId })
      } else {
        this.getMenuList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getMenuList();
    },
    addManual(row) {
      this.popupOptions.title = '매뉴얼 상세'; // 매뉴얼 상세
      this.popupOptions.param = {
        sysMenuId: row ? row.sysMenuId : '',
      };
      this.popupOptions.target = () => import(`${"./manualDetail.vue"}`);
      this.popupOptions.width = '1280px';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>

<style lang="sass">
.menu-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 500px !important
  overflow-y: hidden !important
.menu-content
  height: 100%
  max-height: 400px !important
  overflow-y: auto
.menu-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.custommenu
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .menuCloseIcon
    display: none
.custommenu:hover
  .menuCloseIcon
    display: block
.custommenu.q-field--dense .q-field__control, .custommenu.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.custommenu.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.custommenu.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>